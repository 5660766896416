"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export default function NavLinks({
    children,
}: {
    children: ReactNode;
}) {
    const links: { name: string; url: string }[] = [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Birre",
            url: "/birre",
        },
        {
            name: "Associazione",
            url: "/associazione",
        },
        {
            name: "Contatti",
            url: "/contatti",
        },
    ]
    const pathName = usePathname();

    return (
        <>
            {links.map((l) => {
                if (l.url === "/birre") {
                    return (
                        <li key={l.url} className="flex">
                            <DropdownMenu key={"beers"}>
                                <DropdownMenuTrigger
                                    className={pathName.startsWith("/birre") ?
                                        "pt-2 font-semibold underline"
                                        :
                                        "pt-2 font-medium"
                                    }
                                >
                                    Birre
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>{children}</DropdownMenuContent>
                            </DropdownMenu>
                        </li>
                    );
                }
                return (
                    <li key={l.url} className="flex">
                        <Link
                            className={(pathName === l.url ||
                                (pathName.startsWith(l.url) && l.url !== "/")) ?
                                "pt-2 font-semibold underline"
                                :
                                "pt-2 font-medium"
                            }
                            href={l.url}
                        >
                            {l.name}
                        </Link>
                    </li>
                );
            })}
        </>
    );
}
