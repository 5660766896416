"use client"

import { cn } from "@/lib/utils";
import { Store } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";

export default function ShopLink() {
    const pathName = usePathname();

    return (
        <Link aria-label="shop" href="/shop" className="flex justify-center items-center gap-3">
            <div className={cn(
                (pathName === "/shop") ?
                    "font-semibold underline"
                    :
                    "font-medium",
                "hidden lg:block pt-2"
            )}>
                Shop
            </div>

            <Store className="w-6 h-6 sm:w-8 sm:h-8" />
        </Link>
    )
}
