"use client"

import { Button, buttonVariants } from "./ui/button";
import { signOut } from "next-auth/react"
import { cn } from "@/lib/utils";
import { usePostHog } from "posthog-js/react";

export default function SignOutButton() {
    const posthog = usePostHog()

    function handle() {
        signOut()
        posthog.reset()

    }

    return (
        <Button
            className={cn(buttonVariants({ variant: "secondary" }), "w-full")}
            onClick={handle}>
            Sign out
        </Button>
    )
}
