"use client";

import { getCartLenght } from "@/lib/actions/cart";
import { useCartStore } from "@/lib/stores/cartStore";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useEffect, useState } from "react";

export default function CartIconWithCount() {
    const { data: session, status } = useSession();
    const cartStore = useCartStore();
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (!isMounted) {
            return;
        }
        async function getServerCount() {
            const cartLength = await getCartLenght();
            cartStore.setCount(cartLength);
        }

        if (status === "authenticated") {
            void getServerCount();
        } else {
            cartStore.setCount(cartStore.cart.length);
        }
    }, [isMounted, session]);

    return (
        <div className="relative pr-[5px]">
            <Link aria-label="carrello" href={"/carrello"}>
                <CartIcon className="w-8 sm:w-10" />
                {isMounted && cartStore.count > 0 && (
                    <span className="absolute text-xs sm:text-sm text-center top-[-8px] right-0 px-[0.3rem] sm:px-1.5 py-[0.05rem] min-w-[1.4rem] sm:min-w-[1.5rem] rounded-full bg-orange-600 text-white border-2 border-primary-foreground">
                        {cartStore.count}
                    </span>
                )}
            </Link>
        </div>
    );
}

export function CartIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            width="39"
            height="28"
            viewBox="0 0 39 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5225 6.5H36.5L32.2978 20.5H13.1966L7.84831 6.5L6 2H0"
                stroke="currentColor"
                strokeWidth="3"
            />
            <circle cx="12" cy="25" r="2.5" fill="currentColor" />
            <circle cx="35" cy="25" r="2.5" fill="currentColor" />
        </svg>
    );
}
