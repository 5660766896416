import Link from "next/link";
import { Button, buttonVariants } from "./ui/button";

export default function SignInButton() {
    return (
        <Link className={buttonVariants({ variant: "default" })} href={"/signIn"}>
            Accedi
        </Link>
    )
}
