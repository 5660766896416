"use client"

import SignInButton from "@/components/SignInButton"
import SignOutButton from "@/components/SignOutButton"
import Link from "next/link"
import { useSession } from "next-auth/react"
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"

export default function UserNav() {
    const links: { name: string; url: string }[] = [
        {
            name: "Ordini",
            url: "/ordini",
        },
        {
            name: "Preferenze",
            url: "/preferenze",
        },
    ]
    const { data: session } = useSession()

    return (
        <div className="hidden lg:block">
            <DropdownMenu>
                <DropdownMenuTrigger aria-label="menù utente" className='font-semibold'>
                    <UserIcon />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mr-4 p-4">
                    {!!session ?
                        <div className="text-end text-lg">
                            <ul>
                                {links.map((l) => (
                                    <li key={l.url} className="mb-1">
                                        <Link href={l.url}>{l.name}</Link>
                                    </li>
                                ))}
                            </ul>
                            <div className="border-b border-foreground my-3" />
                            {/* {!!isAdmin && <a className="text-destructive" href={"/admin"} target="_blank">Amministrazione</a>} */}
                            <div className="text-sm mb-4 sensitive">{session.user.email}</div>
                            <SignOutButton />
                        </div>
                        :
                        <SignInButton />
                    }
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}

function UserIcon() {
    return (
        <svg width="20" height="33" viewBox="0 0 20 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="9" r="9" fill="currentColor" />
            <path d="M0 29C0 23.4772 4.47715 19 10 19C15.5228 19 20 23.4772 20 29V33H0V29Z" fill="currentColor" />
            <mask id="path-3-inside-1_443_40" fill="currentColor">
                <path d="M16 9C16 10.5913 15.3679 12.1174 14.2426 13.2426C13.1174 14.3679 11.5913 15 10 15C8.4087 15 6.88258 14.3679 5.75736 13.2426C4.63214 12.1174 4 10.5913 4 9L7.18704 9C7.18704 9.74604 7.48341 10.4615 8.01094 10.9891C8.53847 11.5166 9.25396 11.813 10 11.813C10.746 11.813 11.4615 11.5166 11.9891 10.9891C12.5166 10.4615 12.813 9.74604 12.813 9H16Z" />
            </mask>
        </svg>
    )
}
