import { createContext } from 'react'
import { create, createStore } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { getCart } from '../actions/cart'
import { ArrElement } from '../utils'

export type CartType = NonNullable<Awaited<ReturnType<typeof getCart>>>["cart"]


interface CartState {
    cart: CartType
    count: number
    addCount: () => void
    setCount: (count: number) => void
    add: (cart: ArrElement<CartType>) => void
    remove: (id: string) => void
    empty: () => void
}

export const useCartStore = create<CartState>()(
    persist(
        (set) => ({
            cart: [],
            count: 0,
            addCount: () => set((state) => ({ ...state, count: state.count + 1 })),
            setCount: (count) => set((state) => ({ ...state, count: count })),
            add: (cartItem) => set((state) => ({ ...state, cart: [...state.cart, cartItem] })),
            remove: (id) => set((state) => ({ ...state, cart: [...state.cart.filter(item => item.id !== id)] })),
            empty: () => set(state => ({ ...state, cart: [] }))
        }),
        {
            name: 'cart-storage',
            // skipHydration: true,
            // storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
        }
    )
)

